import React from 'react'

function Quali(props){
	return(<div>
		<h3>Career history</h3><br/>
		<p> At the end of my matric year i worked at Tekkietown. Here I was a sales assisstant and learn to work well with customers . 
		Not only did I learn hard skills but also communication skills and how to work with colleugues. Reference : Manager(Gerswin): 085 354 2716 </p><br/>


     <p>After my working experience at Tekkietown . I got a learnership with BCX and did  ICT Infrastructure. I did a cloud computing, consoling a router and IT essentials. </p><br/>

     <p>At BCX, we had to complete a learnership as part of the program.I was placed at Woolworths Headoffice in Cape Town where i recieved training on working with clients.
      Part of my responsiblities included installing software and helping with IT support duties.I was privileged to experience working in IT handson and not just doing theory. 
      Reference:IT Support Manager(Alfonzo) 0216547890 Here i got to work at Woolworths Headoffice in Town . 
     </p>


		</div>)
}


export default Quali;