import React, { Component } from 'react';
import './App.css';
import Header from './mycomponents/myheader';
import me from "./mycomponents/portfolioPic.jpg";
import Bio from "./mycomponents/bio";
import Contact from "./mycomponents/contact";
import Body from "./mycomponents/body";
import Quali from "./mycomponents/qualifications"
import Projects from "./mycomponents/projects"

function App() {
    return (
        <div className="container">
    		<div className="row">
    			<div className="col-sm-12">
    				<Header name="Cassidy" surname="Theunissen" />

    			</div>
    		</div>
    		<div className="row">
    			<div className="col-sm-4">
    				<img className="Me"src={me}  />
    			</div>
    			<div className="col-sm-4">
    				<Bio title=" Software developer" biography="Biography" details=" deudef hgdd  fewg f wc gcew gdc ewfgewewv c he c yewfegc "/>
    			</div>
    			<div className="col-sm-4">
    				<Contact head="Contact details" num1=" tel: 021 865 7981" num2="cell: 072 764 2193" num3="cassoth24@gmail.com"/>
    		  	</div>
    		  </div><br/><br/>
			<div className="row">
    			<div className="col-4">
    			<Body/>
    			</div>
    			<div className="col-4">
    			<Quali/>
    			</div>
    			<div className="col-4">
    			<Projects/>
    			</div>
    		</div>
    	</div>

        );
}

export default App;