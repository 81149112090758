import React from 'react'

function Body(props){
	return(<div>
		<h3>Personal info</h3><br/>
			
				<li>Nationality: South African  </li>
				<li>Date of birth: 24 September 1999</li>	
				<li>Gender: Female </li>
				<li>Languages: English and Afrikaans</li>
				<li>Highest grade : Matric</li>
				<li>Health:Excellent</li>
				<li>Marital status: Single </li>
				<li>High school: Princeton Secondary</li>
				<li>Subjects: Mathematics, Physics, Life science </li>
				<li>Hobbies: Netball soccer </li>
			
			

		</div>
		);
}
export default Body;