import React from 'react'

function Projects(){
	return(<div><h3>Projects</h3>
		<p> So i completed a few tasks using some of these following languages. </p>
     <img src="https://www.101computing.net/wp/wp-content/uploads/python-logo-1.png"/>
     </div>)
}







export default Projects;