import React from 'react'

function Bio(props){
	return(<header className="Title"><h3> {props.title}</h3> <h4>{props.biography}</h4>
		<p> I'm a very hard working female, with a love for all things code . I'm very keen on learning new things thus my love for coding . 
		I'm active and thus can work very well under pressure . I've learnt that hard work pays off and thus keep in doing this. I'm diligent and eager to work my way to the top.
		I work well in teams because i believe more heads are better that one . </p>
    </header>);
}


export default Bio;